
import { defineComponent } from 'vue'
import VerticalBarChart from '@/midone/components/vertical-bar-chart/Main.vue'
import StackedBarChart from '@/midone/components/stacked-bar-chart/Main.vue'
import HorizontalBarChart from '@/midone/components/horizontal-bar-chart/Main.vue'
import LineChart from '@/components/line-chart/Main.vue'
import DonutChart from '@/midone/components/donut-chart/Main.vue'
import PieChart from '@/components/pie-chart/Main.vue'

export default defineComponent({
  components: {
    VerticalBarChart,
    StackedBarChart,
    HorizontalBarChart,
    LineChart,
    DonutChart,
    PieChart
  }
})
